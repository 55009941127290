<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/questionarios-sesmt">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/questionarios-sesmt"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <h5 class="mb-4">{{ title }}</h5>
                    <div class="">
                        <div class="grid">
                            <div class="field field-checkbox col-12">
                                <label for="ativo" class="mr-2 ml-0">Ativo</label>
                                <InputSwitch id="ativo" v-model="form.ativo" />
                            </div>
                            <div class="field col-6">
                                <div class="field col-6">
                                    <label for="name">Nome do questionário</label>
                                    <InputText
                                        v-model.trim="form.nome"
                                        required="true"
                                        autofocus
                                        autocomplete="off"
                                        placeholder="Informe o nome"
                                        :class="{ 'p-invalid': submitted && !form.nome }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatório.</small>
                                </div>
                                <div class="field col-6">
                                    <label for="tipo">Tipo</label>
                                    <Dropdown
                                        id="tipo"
                                        class="selects"
                                        v-model="form.tipo"
                                        :options="tipos"
                                        required="true"
                                        optionValue="value"
                                        optionLabel="label"
                                        placeholder="Selecione..."
                                        :class="{ 'p-invalid': submitted && !form.tipo }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.tipo">Tipo é obrigatório.</small>
                                </div>
                            </div>
                            <div class="field col-6">
                                <label for="customers">Clientes</label>
                                <PickList
                                    v-model="clientes"
                                    v-model:modelValue="clientes"
                                    dataKey="id"
                                    :showSourceControls="false"
                                    :showTargetControls="false"
                                    :striped-rows="true"
                                >
                                    <template #sourceheader>
                                        <div>Disponíveis</div>
                                        <br />
                                        <InputText placeholder="Pesquise pelo cliente" @input="(e) => onFilterClientes(e)" />
                                    </template>
                                    <template #targetheader>{{ clientes[1]?.length > 0 ? 'Selecionados' : 'Selecionados (Todos)' }} </template>
                                    <template #item="slotProps">
                                        {{ slotProps.item.name }}
                                    </template>
                                </PickList>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12">
                                <div
                                    v-for="(perguntaForm, index) in perguntasForm"
                                    :key="index"
                                    draggable="true"
                                    @dragstart="dragStart(index, $event)"
                                    @dragover.prevent
                                    @dragenter="dragEnter"
                                    @dragleave="dragLeave"
                                    @dragend="dragEnd"
                                    @drop="dragFinish(index, $event)"
                                >
                                    <div class="mb-3">
                                        <div class="mt-2 flex align-items-center">
                                            <span class="mr-2 font-normal" :style="{ fontSize: '16px' }">{{ index + 1 }}</span>
                                            <div :style="{ width: '50%' }">
                                                <Dropdown
                                                    id="pergunta"
                                                    :style="{ width: '100%' }"
                                                    class="selects"
                                                    v-model="perguntaForm.id"
                                                    :options="perguntas"
                                                    :filter="true"
                                                    optionValue="id"
                                                    optionLabel="pergunta"
                                                    placeholder="Selecione..."
                                                    :class="{ 'p-invalid': submitted && !perguntaForm.id }"
                                                    @change="onChangePergunta($event, index)"
                                                >
                                                    <template #option="slotProps, index">
                                                        <div :key="index">
                                                            <div v-if="perguntasForm.findIndex((p) => p.id === slotProps.option.id) < 0">
                                                                {{ slotProps.option.pergunta }}
                                                            </div>
                                                            <div style="opacity: 0.3" v-else-if="perguntaForm.id !== slotProps.option.id">
                                                                {{ slotProps.option.pergunta }}
                                                            </div>
                                                            <div v-else>
                                                                {{ slotProps.option.pergunta }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </Dropdown>
                                                <small class="p-error" v-if="submitted && !perguntaForm.id">Pergunta é obrigatória.</small>
                                            </div>
                                            <div class="field-checkbox m-3">
                                                <Checkbox :value="perguntaForm.obrigatorio" v-model="perguntaForm.obrigatorio" :binary="true" />
                                                <label>Obrigatório</label>
                                            </div>
                                            <Button
                                                v-if="perguntasForm.length > 1"
                                                icon="pi pi-times"
                                                class="ml-2 p-button-rounded p-button-danger p-button-text"
                                                @click="() => perguntasForm.splice(index, 1)"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="inline-flex">
                                    <Button
                                        icon="pi pi-plus font-semibold"
                                        label="Perguntas"
                                        class="p-button-text m-0 px-0 font-semibold text-lg"
                                        @click="() => perguntasForm.push({ id: null, descricao: '' })"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div class="flex flex-row gap-3">
                            <div class="field-checkbox">
                                <InputSwitch v-model="form.inserirAnexo" inputId="inserirAnexo"/>
                                <label for="inserirAnexo">Inserir anexo</label>
                            </div>
                            <div>
                                <div class="field-checkbox">
                                    <Checkbox 
                                        id="anexoObrigatorio"
                                        :disabled="!form.inserirAnexo"
                                        :value="form.anexoObrigatorio"
                                        v-model="form.anexoObrigatorio" 
                                        :binary="true"
                                    />
                                    <label 
                                        :class="{ 'text-500': !form.inserirAnexo }"
                                        for="anexoObrigatorio">Obrigatório</label>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getClientBase } from '@/services/http';
import EnumTipoPergunta from '../../enums/EnumTipoPergunta';

export default {
    data() {
        return {
            form: {
                ativo: true,
                anexoObrigatorio: false
            },
            dragging: -1,
            submitted: false,
            clientes: [],
            perguntas: [],
            perguntasForm: [{ id: null }],
            tiposPergunta: EnumTipoPergunta,
            tipos: [
                {
                    value: 'IN',
                    label: 'Inicial'
                },
                {
                    value: 'AM',
                    label: 'Ambiente'
                },
                {
                    value: 'IF',
                    label: 'Identificação e caracterização de perigo/fator de risco'
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/questionario-sesmt');
        this.$serviceCliente = new BaseService('/customers/findall-semfiltro-tenant');
        this.$servicePergunta = new BaseService('/pergunta-sesmt/findall/ativo');
    },
    async mounted() {
        this.loadPerguntas();
        if (this.$route?.query?.id) {
            const { data } = await getClientBase().get(`/questionario-sesmt/${this.$route?.query?.id}`);
            this.onLoadData(data);
        }
    },
    watch: {
        'form.inserirAnexo'(newValue) {
            if (!newValue) {
                this.form.anexoObrigatorio = false;
            }
        }
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar questionário SESMT' : 'Adicionar questionário SESMT';
        },
        isDragging() {
            return this.dragging > -1;
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
            let formatForm;

            if (this.$route?.query?.id) {
                formatForm = {
                    ativo: this.form.ativo,
                    clientes: this.clientes[1]?.map((customer) => {
                        return customer;
                    }),
                    nome: this.form.nome,
                    perguntas: this.perguntasForm?.map((pergunta, index) => {
                        return {
                            id: pergunta.id,
                            ordem: index + 1,
                            obrigatorio: pergunta.obrigatorio
                        };
                    }),
                    tipo: this.form.tipo,
                    questionarioSESMTPergunta: this.perguntasForm?.obrigatorio
                };
            } else {
                formatForm = {
                    ...this.form,
                    clientes: this.clientes[1]?.map((customer) => {
                        return customer;
                    }),
                    perguntas: this.perguntasForm?.map((pergunta, index) => {
                        return {
                            id: pergunta.id,
                            ordem: index + 1,
                            obrigatorio: pergunta.obrigatorio
                        };
                    }),
                    questionarioSESMTPergunta: this.form.questionarioSESMTPergunta?.map(item => {
                        return {
                            ...item,
                            obrigatorio: this.perguntasForm.find(pergunta => pergunta.id === item.idPerguntaSESMT) ? true : false
                        }
                    })
                };
            }
            this.form = formatForm;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        async onLoadData(data) {
            this.clientes = [[], []];

            this.form = {
                ...data
            };
            this.perguntasForm = data.questionarioSESMTPergunta?.map((qp) => {
                return qp.perguntaSESMT;
            });
            this.clientes = [
                [],
                [
                    ...data.questionarioSESMTCliente?.map((qc) => {
                        return qc.cliente;
                    })
                ]
            ];
        },
        async onLoadDataEdit(data) {
            this.clientes = [
                [],
                [
                    ...data.questionarioSESMTCliente?.map((qc) => {
                        return qc.cliente;
                    })
                ]
            ];

            this.form = data;
            let obrigatorio;
            const questionarioSESMTPergunta = data.questionarioSESMTPergunta?.map((qp) => {
                return qp.perguntaSESMT;
            });

            this.perguntasForm = questionarioSESMTPergunta.map((perguntas) => {
                obrigatorio = data?.questionarioSESMTPergunta?.some(questionarioSESMTPergunta => questionarioSESMTPergunta.idPerguntaSESMT === perguntas?.id && questionarioSESMTPergunta.obrigatorio);
                return {
                    ...perguntas,
                    obrigatorio
                }
            });
        },
        hasError() {
            if (!this.form.nome) return true;
            if (!this.form.tipo) return true;
            if (this.perguntasForm.length <= 0 || this.perguntasForm.find((pergunta) => pergunta.id === null)) return true;
        },
        onError(error) {
            if (error.response?.data?.statusCode === 422) {
                this.$toast.add({ severity: 'error', summary: error.response?.data?.message || error.message, life: 3000 });
                return;
            }
        },
        async loadCustomers(event) {
            const companyId = this.form.companies?.map((c) => c.id);
            const { data } = await this.$serviceCliente.findAll({ filter: event?.target?.value, companyId });

            const clientesSelecionados = this.clientes[1];
            if (clientesSelecionados?.length > 0) {
                this.clientes = [data.items, clientesSelecionados];
            } else {
                this.clientes = [data.items, []];
            }
        },
        async loadPerguntas(event) {
            const companyId = this.form.companies?.map((c) => c.id);
            const { data } = await this.$servicePergunta.findAll({ filter: event?.value, companyId });
            this.perguntas = data
                .sort((a, b) => {
                    return a.descricao - b.descricao;
                })
                .map((item) => {
                    const tipo = this.tiposPergunta.find((tipoPergunta) => tipoPergunta.value === item.tipo)?.label;

                    return {
                        ...item,
                        pergunta: `${item.descricao} - ${tipo}`
                    };
                });
        },
        dragStart(which, ev) {
            ev.dataTransfer.setData('Text', this.id);
            ev.dataTransfer.dropEffect = 'move';
            this.dragging = which;
        },
        dragEnter(ev) {
            if (ev.clientY > ev.target.height / 2) {
                ev.target.style.marginBottom = '10px';
            } else {
                ev.target.style.marginTop = '10px';
            }
        },
        dragLeave(ev) {
            ev.target.style.marginTop = '2px';
            ev.target.style.marginBottom = '2px';
        },
        dragEnd() {
            this.dragging = -1;
        },
        dragFinish(to, ev) {
            this.moveItem(this.dragging, to);
            ev.target.style.marginTop = '2px';
            ev.target.style.marginBottom = '2px';
        },
        moveItem(from, to) {
            if (to === -1) {
                this.removeItemAt(from);
            } else {
                this.perguntasForm.splice(to, 0, this.perguntasForm.splice(from, 1)[0]);
            }
        },
        removeItemAt(index) {
            this.perguntasForm.splice(index, 1);
        },
        onChangePergunta(e, index) {
            if (this.perguntasForm.filter((p) => p.id === e.value).length === 2) {
                this.perguntasForm[index].id = undefined;
            }
        },
        onFilterClientes(e) {
            if (e.target.value?.length >= 3) {
                this.loadCustomers(e);
            } else if (e.target.value?.length === 0) {
                const clientesSelecionados = this.clientes[1];
                if (clientesSelecionados?.length > 0) {
                    this.clientes = [[], clientesSelecionados];
                } else {
                    this.clientes = [[], []];
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.multiSelect {
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
}

.selects-cliente {
    border-radius: 6px;
    padding: 0.25rem 0.5rem;
}

.selects {
    border-radius: 6px;
}

.chips {
    height: 37px;
}

.todo-item {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 14px 8px;
    margin-bottom: 3px;
    background-color: #fff;
    box-shadow: 1px 2px 2px #ccc;
    font-size: 22px;
}

.p-dropdown-item {
    color: red;
}
</style>
